<template>
	<div>
		<jy-dialog type="form" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="global.dialogWidth">
			<div>
				<el-form :model="diaform" ref="diaform" label-width="120px" :rules="rules">
                    <el-form-item label="车辆" prop="vehicleId">
                        <el-input v-model="diaform.vehicleNo" readonly @focus="chooseCar"></el-input>
                    </el-form-item>
                    <el-form-item label="预切换线路" prop="routeId">
                        <el-select v-model="diaform.routeId">
                            <el-option v-for="one in routeList" :key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="切换日期" prop="oDate">
                        <el-date-picker :picker-options="expireTimeOption" v-model="diaform.oDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
                        <span style="color:#999999;">(到达该日期后车辆上线时进行切换)</span>
                    </el-form-item>
					<el-form-item label="备注" prop="remark">
						<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="diaform.remark">
						</el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</jy-dialog>
        <car-selectbatch ref="carSelectbatch" @choose="chooseCarList"></car-selectbatch>
	</div>
</template>
<script>
import carSelectbatch from '@/components/pages/admin/resourcesAllocation/carDriver/carSelectbatch'
export default {
    data() {
        return {
            // 对话框类型
            type: '',
            title: '',

            diaform: {
                vehicleId:'',
                vehicleNo:'',
                routeId:'',
                oDate:'',
                remark:'',
            },
            dialogVisible: false,

            routeList: [],
            carList:[],

            rules: {
                vehicleId: [
                    {
                        required: true,
                        message: "请选择车辆",
                        trigger: "change",
                    },
                ],
                routeId: [
                    {
                        required: true,
                        message: "请选择线路",
                        trigger: "change",
                    },
                ],
                oDate: [
                    {
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
            },
            expireTimeOption: {
                disabledDate(date) {
                    return date.getTime() < Date.now() - 24 * 60 * 60 * 1000
                }
            },
        };
    },
    created(){
        this.getRouteList()
    },
    watch: {
        dialogVisible(newview) {
            if (!newview) {
                this.resetForm("diaform");
            }
        },
    },
    components: {
        carSelectbatch,
    },
    methods: {
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            return this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        // 打开对话框
        init(option, type) {
            console.log(option)
            this.dialogVisible = true;
            this.type = type || 'add';
            if (this.type == 'update') {
                this.title = '编辑';
                this.$nextTick(() => {
                    this.diaform = {
                        ...this.diaform,
                        ...option,
                    };
                });
            } else {
                this.title = '新增';
            }
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.carList = []
        },

        cancel() {
            this.dialogVisible = false;
            console.log('cancel');
        },
        save() {
            this.$refs.diaform.validate((valid) => {
                if (valid) {
                    let url = '';
                    if (this.type == 'add') {
                        url = '/waybillPlan/add'
                    } else {
                        url = '/waybillPlan/update'
                    }
                    let option = this.diaform
                    this.$http.post(url, option).then((res) => {
                        console.log(res)
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        })
                        this.dialogVisible = false
                        this.$emit('updateList')
                    })
                } else {
                    return false
                }
            });
        },
        chooseCar(){
            this.$refs.carSelectbatch.init(this.carList)
        },
        chooseCarList(list){
            console.log(list)
            this.carList = list
            this.diaform.vehicleId = list.map(item=>item.vehicleId).join(',')
            this.diaform.vehicleNo = list.map(item=>item.plateNum).join(',')
        },
    },
};
</script>
<style lang="scss" scoped>
</style>