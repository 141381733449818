<template>
    <div id="carRoterChange">
        <jy-query ref="form" :model="form">
            <jy-query-item label="车牌号:" prop="userNa">
                <el-input placeholder="请输入" v-model="form.userNa"></el-input>
            </jy-query-item>
            <jy-query-item label="自编号:" prop="userNa">
                <el-input placeholder="请输入" v-model="form.userNa"></el-input>
            </jy-query-item>
            <jy-query-item label="切换日期" prop="oDate">
                <el-date-picker v-model="form.oDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item prop="roleId" label="状态:">
                <el-select v-model="form.roleId" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="切换后线路" prop="routeIds" :span="1.6">
                <el-select v-model="form.routeIds" placeholder="请选择" multiple filterable>
                    <el-option v-for="item in routeList" :key="item.routeId" :label="item.cName" :value="item.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('scheduleSystemSettingsAccountManagementList')">查询</el-button>
                <el-button type="primary" @click="resetForm('form')" v-if="btnexist('scheduleSystemSettingsAccountManagementReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addUdate()" v-if="btnexist('scheduleSystemSettingsAccountManagementInsert')">新增</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column type="index" label="序号" width="60"></jy-table-column>
            <jy-table-column prop="userNa" label="车牌号" min-width="400" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="markNa" label="切换日期" min-width="100"></jy-table-column>
            <jy-table-column prop="orgNa" label="切换后线路" min-width="100"></jy-table-column>
            <jy-table-column label="状态" min-width="80">
                <template slot-scope="scope">{{scope.row.active|state}}</template>
            </jy-table-column>
            <jy-table-column prop="createT" label="创建时间" min-width="100"></jy-table-column>
            <jy-table-column prop="createT" label="创建人" min-width="100"></jy-table-column>
            <jy-table-column prop="createT" label="备注" min-width="160"></jy-table-column>
            <jy-operate v-bind:list="operateList" width="120"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update ref="addUpdate" @updateList="getList"></add-update>
    </div>
</template>
<script>
import addUpdate from '@/components/pages/admin/timing/carRoterChange/addUpdate'
import {btnMixins} from '@/common/js/button.mixin'

export default {
    data() {
        return {
            statusList: [
                {
                    label:'全部',
                    value:'',
                },
                {
                    label:'待切换',
                    value:'1',
                },
                {
                    label:'已切换',
                    value:'2',
                },
            ],
            routeList: [],
            form: {
                userNa: '',
                roleId: '',
            },
            pageSize: 10,
            total: 10,
            pageIndex: 1,
            dataList: [],
            operateList: [],

            btnMenuId:'scheduleSystemSettingsAccountManagement',
        }
    },
    mixins:[btnMixins],
    components: {
        addUpdate,
    },
    activated() {
    },
    created() {
        this.getRouteList()
        this.getList()
        this.setOperateList()
    },
    filters: {
        state(val) {
            if (val == 1) {
                return '启用'
            } else {
                return '禁用'
            }
        },
    },
    methods: {
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.addUdate,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsAccountManagementUpdate')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('scheduleSystemSettingsAccountManagementDelete')
                    },
                },
            ]
            this.operateList = l
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        getList() {
            let option = {
                sysUser: this.form,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            let url = '/sys/user/pageQuerySysUser'
            console.log(option)
            this.$http.post(url, option,{ isRequestParam: false }).then(({ detail }) => {
                console.log(detail)
                this.dataList = detail.list.map(v => {
                    return {
                        ...v.sysOrg,
                        ...v.sysRole,
                        ...v.sysUser,
                        createT: this.$util.formatTime(v.sysUser.createT)
                    };
                });
                this.total = detail.total
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.oncheck()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该定时任务?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = row.userId
                    let url = '/sys/user/delete'
                    this.$http.post(url, option,{ isRequestParam: false }).then(res => {
                        // console.log(res)
                        this.$message({
                            type: 'success',
                            message: res.msg,
                        })
                        this.getList()
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        addUdate(option) {
            if (option) {
                console.log(option)
                this.$refs.addUpdate.init(option, 'update')
            } else {
                this.$refs.addUpdate.init()
            }
        },
    },
}
</script>
<style scoped="carRoterChange" lang="scss">
</style>
